import { DeviceInfo } from "@tonconnect/protocol";

export const CURRENT_PROTOCOL_VERSION = 2;

export const MIN_PROTOCOL_VERSION = 2;

export const tonConnectDeviceInfo: DeviceInfo = {
  platform: "browser",
  appName: "tonkeeper",
  appVersion: "1.0.0",
  maxProtocolVersion: CURRENT_PROTOCOL_VERSION,
  features: ["SendTransaction", { name: "SendTransaction", maxMessages: 4 }],
};

export enum TonConnectBridgeType {
  Remote = "remote",
  Injected = "injected",
}
